html * {
    font-family: "Nunito", sans-serif !important;
    scroll-behavior: smooth !important;
}

@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap');

/* onboarding start*/

.single-item-name-tag {
    font-weight: 600;
    font-size: 22px;
}

.single-item-price {
    font-weight: 700;
    font-size: 16px;
    color: #f3870d;
}

.single-item-mrp {
    font-weight: 600;
    font-size: 12px;
}

.single-product-cart-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    width: 100%;
    border: none;
    height: 50px;
    color: #fff;
}

.single-product-prescription {
    border: 1px solid #d9d9d9;
    color: #d9d9d9;
    width: 50%;
    border-radius: 15px;
}

.single-product-img {
    width: 100%;
    height: 300px;
    border-radius: 16px;
    padding: 2px;
}

.mobile-onboarding-img {
    background-image: url(../images/onBoarding.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
}

.mobile-onboarding-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    width: 100%;
}

.mobile-explore-btn {
    background: linear-gradient(93.31deg, #ffffff 12.5%, #fffef9 100.78%);
    border-radius: 8px;
    border: none;
    width: 100%;
    color: #fd8814;
    padding: 8px 12px;
    font-weight: 700;
    font-size: 16px;
}

.mobile-login-btn {
    width: 50%;
    border: none;
    color: #fff;
    width: 100%;
    padding: 8px 12px;
    background: transparent;
    font-weight: 700;
    font-size: 16px;
}

/* onboarding end*/

/* footer */

.mobile-footer-icon-text {
    font-weight: 500;
    font-size: 13px;
    color: #fd8814;
}

.mobile-footer-icon {
    color: #ff9a62;
}

.mobile-footer-btn {
    border-radius: 12px;
    background: rgba(255, 154, 98, 0.1);
}

.mobile-footer-container {
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(90, 108, 234, 0.1);
    border-radius: 22px 22px 0px 0px;
    z-index: 999;
}

/* footer ends */

/* Notifications start */

.mobile-ntfcn-head {
    font-weight: 700;
    font-size: 25px;
    color: #09051c;
    background: url(../images/sidebg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.mobile-ntfcn-header {
    opacity: 0.3;
}

.mobile-ntfcn-box {
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
}

.mobile-ntfcn-box-head {
    font-weight: 600;
    font-size: 15px;
    color: #09051c;
}

.mobile-ntfcn-box-text {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #3b3b3b;
    mix-blend-mode: normal;
    opacity: 0.6;
}

/* Notifications end */

/* back button start */

.mobile-back-button {
    /* z-index: 9999; */
    background-color: #fff1e4;
    border-radius: 15px;
    padding-top: 2px;
    padding-bottom: 6px;
    padding-left: 9px;
    padding-right: 10px;
    /* top: 30px;
    left: 4px; */
}

.mobile-back-button-title {
    font-weight: bold;
    margin-left: 4.5rem;
    margin-top: 2rem;
}

.mobile-back-button-center-title {
    margin-left: 4.5rem;
    margin-top: 2rem;
    align-items: center;
}

/* back button end */

/* sign up start */

.mobile-signup-style {
    background-image: url("../images/login.bg.png");
    background-size: contain;
    background-repeat: round;
    height: 70vw;
    opacity: 0.2;
    z-index: -99;
}

.signup-logo {
    position: absolute;
    top: 10%;
    width: 80%;
    right: 10%;
    text-align: center;
}

.mobile-signup-input {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    color: none;
    outline: none;
    text-align: center;
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid #f4f4f4;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 15px;
}

.mobile-signup-input:focus {
    box-shadow: none;
    background: none;
    outline: none;
    border: none;
}

.mobile-input-91 {
    background-color: #fff !important;
    border: none !important;
    box-shadow: none !important;
}

.mobile-signup {
    color: #000000;
    border: none;
    box-shadow: none !important;
    border-radius: 0px;
}

.mobile-signup:focus {
    box-shadow: none;
    background: #fff;
    outline: none;
    border: none;
}

.mobile-signup:active {
    box-shadow: none;
    background: #fff;
    outline: none;
    border: none;
}

.mobile-signup-btn {
    width: 100%;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 12px;
    border: none;
    color: #ffffff;
}

/* sign up end */

/* subscription success start */

.mobile-subs-success-bg {
    opacity: 0.2;
    background-image: url(../images/successBG.png);
    height: 30vh;
    width: 100%;
    background-size: cover;
}

.mobile-subs-success-img {
    position: relative;
    bottom: 90px;
    object-fit: cover;
}

.mobile-subs-success-head {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;
    bottom: 80px;
}

.mobile-subs-success-text {
    font-weight: 700;
    font-size: 23px;
    text-align: center;
    color: #09051c;
    position: relative;
    bottom: 70px;
}

.mobile-subs-success-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    border: none !important;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
}

.mobile-subs-success-btn {
    position: relative;
    bottom: 55px;
}

/* subscription success end */

/* order success start */

.mobile-order-success-btn {
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.05em;
    background: linear-gradient(91.88deg, #fead1d 28.33%, #f9a84d 93.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    border: 2px solid #fead1d;
}

.mobile-order-success-button {
    position: relative;
    bottom: 60px;
}

/* order success end */

/* wallet start */

.mobile-wallet-bg {
    background-image: url(../images/walletbg.png);
    height: 39vh;
    width: 100%;
    background-size: cover;
}

.mobile-wallet-name {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
}

.mobile-wallet-total {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    color: #ffffff;
}

.mobile-wallet-round {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border: 5px solid rgba(218, 99, 23, 0.28);
}

.mobile-wallet-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
    border: none !important;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
}

.mobile-wallet-container {
    background: rgba(254, 173, 29, 0.1);
    mix-blend-mode: normal;
    border-radius: 15px;
}

.mobile-wallet-text {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.428571px;
    color: #da6317;
}

.mobile-wallet-amt {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.428571px;
    color: #09051c;
}

/* wallet end */

/* Add pickup and drop start */

.mobile-add-pickup-bg {
    background: url(../images/sidebg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5vh;
}

.mobile-pickup-drop-heading {
    font-weight: 700;
    font-size: 31px;
    width: 80%;
    color: #09051c;
}

.mobile-bell-icon {
    width: 45px;
    height: 45px;
    background: #fafdff;
    box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
    padding: 6px;
}

.mobile-add-pickup-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 12px;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #fefeff;
    border: none;
}

/* Add pickup and drop end */

/* completed order start */

.mobile-cmpltd-order-round {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border: 5px solid rgba(218, 99, 23, 0.28);
}

.mobile-cmpltd-order-text {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    color: #09051c;
}

.mobile-cmpltd-order-rate {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #3b3b3b;
    mix-blend-mode: normal;
    opacity: 0.3;
}

.mobile-cmpltd-order-cmnt {
    border: none;
    background: none;
    width: 100%;
}

.mobile-cmpltd-order-cmnt::placeholder {
    font-weight: 400;
    font-size: 14px;
    /* identical to box height */
    letter-spacing: 0.5px;
    color: #3b3b3b;
    mix-blend-mode: normal;
    opacity: 0.3;
}

.mobile-cmpltd-order-cmnt:focus {
    box-shadow: none;
    background-color: none;
    outline: none;
}

.mobile-cmpltd-order-submit {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    font-weight: 700;
    font-size: 14px;
    color: #fefeff;
    border: none !important;
    width: 60%;
}

.mobile-cmpltd-order-skip {
    background: #ffffff;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.2);
    border-radius: 15px;
    font-size: 14px;
    border: none !important;
    color: #fea24b;
}

.mobile-cmpltd-order-cmnt-div {
    background: #ffffff;
    mix-blend-mode: normal;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
}

/* completed order end */

/* profile starts  */

.membership-color {
    background-color: rgba(254, 173, 29, 0.1);
    color: #fead1d;
}

.profile-name-header {
    font-weight: 700;
    font-size: 27px;
}

.profile-opt {
    background: #ffffff;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 12px;
}

.profile-btns {
    font-size: 1.5rem;
    color: #fead1d;
}

.profile-tag {
    font-weight: 600;
    font-size: 15px;
    color: #09051c;
}

/* profile ends  */

/* rate meal start */

.mobile-rate-meal-bg {
    background-image: url(../images/bg.png);
    height: 39vh;
    width: 100%;
    background-size: cover;
}

.mobile-rate-meal-round {
    height: 180px;
    width: 180px;
    border-radius: 50%;
    /* background: url(../images/meal.png);
    background-repeat: no-repeat;
    background-size: cover; */
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border: 1px solid rgba(218, 99, 23, 0.28);
}

/* rate meal end */

/* confirm order start */

.mobile-card5 {
    width: 100%;
    background-color: white;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
    padding-bottom: 1em;
}

.confirm-order-button {
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    /* margin-inline: 14px; */
    /* padding-bottom: 10%; */
    background: url(../images/Group1.png),
        linear-gradient(98.81deg,
            rgba(254, 162, 75, 1) -0.82%,
            rgba(253, 136, 20, 1) 101.53%);
    background-size: cover;
    background-repeat: no-repeat;
    /* margin-top: 35px; */
    color: #fff;
}

.confirm-button {
    background: #fefeff;
    border-radius: 15px;
    /* margin-top: 12px;
    margin-bottom: -4.7em; */
}

.edit-confirm {
    /* margin-top: 0.2em; */
    margin-right: 1em;
    font-weight: 700;
    color: #f8963b;
}

/* confirm order end */

/* my coupon starts */

.mobile-coupon-bg {
    background: url(../images/sidebg.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.mobile-coupon-header {
    font-weight: 700;
    font-size: 25px;
    color: #09051c;
}

.mobile-coupon-offer {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    mix-blend-mode: normal;
    border-radius: 16px;
}

.mobile-coupon-title-1 {
    font-weight: 700;
    font-size: 17px;
    color: #ffffff;
}

.mobile-coupuon-order-btn {
    bottom: 18%;
    right: 25%;
}

.mobille-coupon-btn1 {
    background: #ffffff;
    box-shadow: 6px 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border: none;
    padding: 8px 10px;
    font-weight: 700;
    font-size: 10px;
}

/* my coupon ends  */

/* sign up process start */

.mobile-sign-up-bg-img {
    background: url(../images/sidebg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.mobile-sign-up-head {
    font-weight: 700;
    font-size: 25px;
    color: #09051c;
}

.mobile-sign-up-content {
    font-weight: 400;
    font-size: 14px;
    margin-top: -2vh;
    color: #000000;
}

.mobile-sign-up-input {
    background: #ffffff;
    box-shadow: 0px 0px 80px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
    /* margin-top: 5px; */
    border: none;
}

.mobile-sign-up-input-group:focus {
    outline: none !important;
    box-shadow: none !important;
}

.mobile-sign-up-input-group:active {
    outline: none !important;
    box-shadow: none !important;
}

.mobile-sign-up-address-type {
    font-weight: 700;
    font-size: 14px;
    color: #09051c;
}

.mobile-sign-up-adress-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    border: none;
    width: 30%;
    color: #ffffff;
}

.mobile-sign-up-adress-btn-2 {
    background: #fdeed5;
    border-radius: 15px;
    border: none;
    color: #602602;
    width: 30%;
}

.mobile-sign-up-adress-btn-3 {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    border: none;
    padding: 12px 35px;
    color: white;
}

.mobile-address-input {
    background: #ffffff;
    box-shadow: 0px 0px 80px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
    border: none;
}

.mobile-address-input:focus {
    outline: none;
    border: none;
}

.mobile-address-input:active {
    outline: none;
    border: none;
}

/* sign up process end */

/* editlocation start*/

.mobile-card {
    background-color: white;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
}

.mobile-card1 {
    width: 90vw;
    background-color: white;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
    margin-top: 20px;
}

/* editlocation end */

/* payment mtd start */

.mobile-payment-heading {
    background: url("../images/sidebg.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.payment-tag {
    margin-top: 100px;
}

.mobile-payment-header {
    font-weight: 700;
    font-size: 25px;
}

.pymt-security {
    width: 70%;
    font-size: 12px;
}

.mobile-card2 {
    background-color: white;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
    font-family: Nunito Sans;
    font-weight: 700;
    font-size: 13px;
}

.payment-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    height: 57px;
    border-radius: 15px;
}

/* pymt mtd stop */

/* running orders starts */

.mobile-runningorders-card {
    background: #ffffff;
    box-shadow: 0px 0px 50px 20px #8796ff29;
    border-radius: 22px;
    mix-blend-mode: normal;
    border-radius: 16px;
}

.mobile-runningorders-cancelbutton {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 10px;
    border: none;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #ffffff;
}

/* mobile-running orders css ends here  */

.mobile-runningorders-backtohomebutton {
    background-color: #fea24b;
    /* margin-bottom: 30px; */
    /* width: 90vw; */
    box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
    position: absolute;
    border: none;
    text-align: center;
    /* margin-top: 90%; */
    height: 60px;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
}

/*home main */

.mobile-searchBar {
    border-radius: 12px;
    height: 50px;
    box-shadow: inset 0px 4px 45px rgba(0, 0, 0, 0.08);
}

.mobile-inventory-search::placeholder {
    color: #aca8a5;
    font-size: 14px;
}

.mobile-inventory-search:focus {
    box-shadow: none;
    background-color: none;
    outline: none;
}

.mobile-inventory-search {
    border: none;
    background: none;
}

.mobile-home-bg-img {
    background: url(../images/sidebg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.mobile-span-home {
    font-weight: 800;
    font-size: 18px;
    color: #38393d;
    /* margin-top: 15px;
    padding: 3px; */
}

.mobile-home-span-2 {
    font-weight: 500;
    font-size: 13px;
    color: #696b6d;
}

.mobile-home-span-3 {
    font-weight: 600;
    font-size: 17px;
    text-align: right;
    color: #fd8814;
    /* padding: 10px; */
    /* margin-top: 10px; */
}

.grid-template-columns-prod {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-items: center;
}

.home-heading {
    font-weight: 700;
    font-size: 15px;
    /* identical to box height, or 20px */
    color: #09051c;
}

.mobile-home-searchBar {
    background: #ffffff;
    mix-blend-mode: normal;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 0.7em;
}

.mobile-home-search::placeholder {
    color: #aca8a5;
    font-size: 14px;
}

.mobile-home-search:focus {
    box-shadow: none;
    background-color: none;
    outline: none;
}

.mobile-home-search {
    border: none;
    background: none;
}

/* .mobile-home-search .placeholder {
    font-weight: 500;
    font-size: 12px;

    color: #b3b3b3;
} */

.mobile-btm-brdr {
    border-bottom: 5px solid #f0f0f0;
    padding: 1em;
}

.mobile-home-category {
    font-weight: 700;
    font-size: 18px;
    color: #09051c;
}

.mobile-home-category-div {
    background: rgba(217, 217, 217, 0.3);
    border-radius: 13px;
    width: 85px;
    height: 99px;
    font-weight: 650;
    font-size: 12px;
    text-align: center;
}

/* .mobile-home-essential-div {
    border-radius: 12px;
    width: 100px;
    height: 125px;
    font-weight: 840;
    font-size: 13px;
    text-align: center;
    color: #ffffff;
} */

.mobile-home-essential-div {
    border-radius: 12px;
    width: 115px;
    height: 145px;
    font-weight: 840;
    font-size: 13px;
    /* identical to box height, or 17px */
    text-align: center;
    color: #ffffff;
}

/* .mobile-home-essential-tag {
    width: 70px;
    height: 17px;
    font-weight: 650;
    font-size: 9px;
    background: white;
    color: #602602;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 15%;
} */

.mobile-home-top-offer {
    font-weight: 700;
    font-size: 18px;
    /* identical to box height, or 20px */
    color: #09051c;
}

.mobile-home-offer-img {
    margin: 0;
    width: 88vw;
    height: 166px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 15px;
    box-shadow: 6px 4px 20px #ececec;
    /* identical to box height, or 20px */
    color: #ffffff;
}

.main-menu {
    display: grid !important;
    overflow-x: scroll !important;
    background: none !important;
}

.hidden-scroll-bar::-webkit-scrollbar {
    display: none;
}

.cpn-btn {
    background: #ffffff;
    box-shadow: 6px 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border: none;
    padding: 8px 10px;
    font-weight: 700;
    font-size: 12px;
    color: #009c51;
}

.mobile-coupuon-btn {
    bottom: 18%;
    right: 25%;
}

.mobile-home-cpn-img {
    width: 100%;
    height: 35vw;
    border-radius: 15px;
}

.mobile-coupon-edit-img {
    width: 100%;
    height: 40vw;
    border-radius: 20px;
}

.mobile-home-coupon-txt {
    color: rgba(253, 136, 20, 0.6);
    font-weight: 840;
    font-size: 17px;
    /* identical to box height, or 17px */
}

.mobile-end-cnt {
    font-weight: 600;
    font-size: 12px;
    color: #09051c;
}

/*home main */

.incomplete {
    border: none;
    text-align: center;
    margin-top: 90%;
    height: 60px;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
}

.mobile-image {
    background: url("../images/sidebg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    overflow-x: hidden;
}

.carousel {
    /* height: 100%; */
    transition: set-nth(carousel-transition, 1, height);
    position: relative;
}

.mobile-product-size {
    font-size: 14px;
    font-weight: 600;
    /* identical to box height */
    color: #070217;
}

.button-menus {
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    background: none;
    color: #b0afb5;
}

.button-menus {
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    background: none;
    color: #b0afb5;
}

.mobile-product-size {
    padding-left: 15px;
    padding-top: 15px;
}

.mobile-product-btns {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    width: 80%;
    padding: 12px 16px;
    margin-left: 1em;
    height: 4em;
}

.mobile-button-minus {
    border: none;
}

/* .card-button {
    background: #F5F5F7;
} */

.mobile-button-minus {
    border: none;
}

.card-button {
    background: #f5f5f7;
    border-radius: 15px;
    padding: 12px 16px;
}

.mobile-product-btnmnu {
    padding-left: 6.3em;
    /* padding-left: 95px; */
}

/*
.mobile-pdt-mins {
    padding-left:60px;
} */

.mobile-product-last-button {
    margin-top: 4em;
    margin-right: 2em;
}

/* pickupanddrop start */

.sidebg {
    background: url(../images/sidebg.png);
    background-repeat: no-repeat;
    object-fit: cover;
    width: 100%;
}

.pickup-div {
    /* background: hwb(0 0% 100% / 0.1); */
}

.pickup-div {
    /* background: hwb(0 0% 100% / 0.1); */
}

.no-border {
    border: none;
    border-color: white;
    border-bottom: 2px solid rgba(255, 144, 18, 0.2);
}

.form-control .no-border {
    outline-style: none;
}

.form-control .no-border {
    outline-style: none;
}

.no-border::content {
    border: none;
    border-width: 0;
}

.no-border1 {
    border: none;
    border-color: white;
    border-bottom: 2px solid rgba(217, 217, 217, 0.1);
}

/* .pickup-div2 {
    margin-left: 10px;
    width: 55%;
} */

.pickup-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    height: 50px;
    border-radius: 15px;
    border: none;
}

.mobile-image {
    background: url("../images/sidebg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    overflow-x: hidden;
}

.carousel {
    /* height: 100%; */
    transition: set-nth(carousel-transition, 1, height);
    position: relative;
}

.mobile-product-size {
    font-size: 14px;
    font-weight: 600;
    /* identical to box height */
    color: #070217;
}

.button-menus {
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    background: none;
    color: #b0afb5;
}

.button-menus {
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    background: none;
    color: #b0afb5;
}

.mobile-product-size {
    padding-left: 15px;
    padding-top: 15px;
}

.mobile-product-btns {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    width: 80%;
    padding: 12px 16px;
    margin-left: 1em;
    height: 4em;
}

.mobile-button-minus {
    border: none;
}

.card-button {
    background: #f5f5f7;
    border-radius: 15px;
    border: none;
    padding: 12px 16px;
}

.mobile-product-btnmnu {
    padding-left: 6.3em;
    /* padding-left: 95px; */
}

/*
.mobile-pdt-mins {
    padding-left:60px;
} */

.mobile-product-last-button {
    margin-top: 4em;
    margin-right: 2em;
}

/* pickupanddrop start */

.sidebg {
    background: url(../images/sidebg.png);
    background-repeat: no-repeat;
    object-fit: cover;
    width: 100%;
}

.pickup-div {
    background: hwb(0 0% 100% / 0.1);
}

.pickup-div {
    background: hwb(0 0% 100% / 0.1);
}

.no-border {
    border: none;
    border-color: white;
    border-bottom: 2px solid rgba(255, 144, 18, 0.2);
}

.no-border::content {
    border: none;
    border-width: 0;
}

.no-border1 {
    border: none;
    border-color: white;
    border-bottom: 2px solid rgba(217, 217, 217, 0.1);
}

/* explore medicine */

.medicine-search {
    background: #f2f1f1;
    mix-blend-mode: normal;
    border-radius: 15px;
    border: none;
    outline: none;
}

.hidden-scroll-bar::-webkit-scrollbar {
    display: none;
}

.med-img {
    display: grid;
}

.med-img {
    display: grid;
    overflow-x: scroll;
}

.fil-sort {
    display: grid;
    overflow-x: scroll;
    font-size: 12px;
}

.drop-down-srt {
    border-radius: 15px;
}

.store-img {
    display: grid;
    overflow-x: scroll;
}

.offer-store-name {
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
}

.med-time {
    font-size: 12px;
    font-weight: 400;
}

.med-store-names {
    display: grid;
    overflow-x: scroll;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
}

.toap-deal-med {
    display: grid;
    overflow-x: scroll;
}

.toap-deal-med {
    display: grid;
    overflow-x: scroll;
}

.Recommended-store-near {
    display: grid;
}

.medical-store-near {
    display: grid;
    overflow-x: scroll;
}

.Recommended-store-near {
    display: grid;
    overflow-x: scroll;
}

.allstore-details {
    border-bottom-style: dashed;
    opacity: 0.2;
}

.view-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    /* height: 50px; */
    border-radius: 10px;
    border: none;
}

.shop-carts {
    padding-bottom: 30%;
    padding-top: 8px;
}

/* explore medicine */

.btn-back {
    box-shadow: 0 0 20px 0px rgba(20, 78, 90, 0.2);
    background-color: white;
    border-radius: 12px;
}

.pickup-div2 {
    margin-left: 10px;
    width: 55%;
}

.pickup-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    height: 50px;
    border-radius: 15px;
    border: none;
}

.selects-button {
    background: white;
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    outline: none;
}

.selects-button :hover {
    border: none;
    outline: none;
}

.selects-button ::after {
    border: none;
    outline: none;
}

.selects-button :hover {
    border: none;
    outline: none;
}

.selects-button ::after {
    border: none;
    outline: none;
}

.select-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
}

.select-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    height: 50px;
    border-radius: 15px;
    border: none;
}

/* pickupanddrop end */

.verification {
    font-weight: 700;
    font-size: 25px;
    color: #09051c;
}

.code {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
}

.card {
    background: #ffffff;
    box-shadow: 4px 5px 10px rgba(255, 144, 18, 0.1);
    border: none;
}

.card>span {
    font-weight: 500;
    font-size: 23px;
    text-align: center;
    color: #ff9012;
}

.footer-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    color: #ff9012;
}

.footer-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    height: 57px;
    border-radius: 15px;
}

/* any store css starts here */

/* any store css starts here */

/* .form-check-input:checked  {
    background-color: #0d6efd;
    border-color: #0d6efd;
} */

.mobile-product-searchbtn {
    background: #fafdff;
    box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
}

.frame-img-any-store {
    background: linear-gradient(90deg, #dd5e89 0%, #f7bb97 100%);
    mix-blend-mode: normal;
    margin-left: 1em;
    mix-blend-mode: normal;
    border-radius: 16px;
    margin-right: 2em;
    width: 93%;
}
.frame-video-any-store {
    margin-left: 1em;
    mix-blend-mode: normal;
    border-radius: 16px;
    margin-right: 2em;
    width: 93%;
}

.anystore-div {
    /* display: flex;
    position: relative; */
    background: linear-gradient(98.81deg, #f3c2c4 -0.82%, #ebb0b6 101.53%);
    /* object-fit: cover;
    background-repeat: no-repeat; */
    margin-left: 2em;
    mix-blend-mode: normal;
    border-radius: 16px;
    margin-right: 2em;
}

.anystore-pattern {
    margin-left: -8em;
    margin-top: 5.8em;
}

.anystore-east {
    font-weight: 500;
    font-size: 12px;
    color: #fff;
}

.anystore-off {
    padding-left: 2em;
    font-size: 16px;
}

.mobile-anystore-searchBar {
    background: #ffffff;
    mix-blend-mode: normal;
    background: #f4f4f4;
    border-radius: 12px;
    padding: 0.9em;
}

.mobile-anystore-card {
    box-sizing: border-box;
    border: 2px solid #d9d9d9;
    border-radius: 10px;
    margin-inline: 36px;
    padding-bottom: 12px;
    width: 85%;
}

.mobile-anystore-card-list {
    box-sizing: border-box;
    border: 2px solid #d9d9d9;
    border-radius: 10px;
    margin-inline: 36px;
    padding-bottom: 12px;
    width: 87%;
}

.anystore-card-button {
    background: #f5f5f7;
    border-radius: 15px;
    padding: 3px 16px;
}

.anystore-additem {
    font-weight: 500;
    font-size: 12px;
    color: #b3b3b3;
}

.mobile-Anystore-colorcard {
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    margin-inline: 14px;
    padding-bottom: 27px;
    background: url(../images/Group1.png),
        linear-gradient(98.81deg,
            rgba(254, 162, 75, 1) -0.82%,
            rgba(253, 136, 20, 1) 101.53%);
    /* color: #fff; */
    /* background-image: url(../images/sidebg.png); */
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 35px;
    color: #fff;
}

.btn-any-store {
    background: #fefeff;
    border-radius: 15px;
}

.anystr-cancel-button {
    background: white;
    border-radius: 15px;
    border: 2px solid #ff9012;
    outline: none;
}

.anystr-done-button {
    background: #ff9012;
    border-radius: 15px;
    border: 2px solid #ff9012;
    outline: none;
}

.checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid red;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* any store ends */

/* cart start */

.mobile-cart-bg-img {
    background-image: url(../images/sidebg.png);
    background-repeat: no-repeat;
    background-size: contain;
    /* background-position: right; */
}

.mobile-cart-items {
    background: #ffffff;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
}

.mobile-cart-spicy {
    font-weight: 500;
    font-size: 15px;
    color: #09051c;
}

.mobile-cart-warenik {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #a7a4a4;
    mix-blend-mode: normal;
    word-break: break-word;
}

.mobile-cart-price {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #3b3b3b;
    mix-blend-mode: normal;
}

.mobile-cart-savings-bg {
    background: #9cff90;
    padding: 1em;
}

.mobile-cart-savings {
    font-weight: 700;
    font-size: 18px;
    line-height: 131.02%;
    color: #0e7401;
}

.mobile-cart-edit {
    /* font-weight: 500; */
    font-size: 17px;
    letter-spacing: 0.5px;
    color: #ec2338;
}

.mobile-cart-complete-bg {
    background: #f7f4f4;
}

.mobile-cart-complete {
    font-weight: 700;
    font-size: 17px;
    line-height: 131.02%;
    color: #09051c;
}

.mobile-meals-card {
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(90, 108, 234, 0.1);
    border-radius: 19px;
    width: 20vh;
}

.mobile-cart-chicken {
    font-weight: 700;
    font-size: 15px;
    color: #09051c;
}

.mobile-cart-wijie {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #3b3b3b;
    mix-blend-mode: normal;
    opacity: 0.5;
}

.mobile-cart-item-price {
    font-weight: 600;
    line-height: 131.02%;
    color: #09051c;
}

.mobile-cart-apply {
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #000000;
}

.mobile-cart-coupon-card {
    background: #ffffff;
    box-shadow: 0px 0px 40px #f0f0f0;
    border-radius: 12px;
}

.mobile-cart-coupon-inner-card {
    background: rgba(222, 219, 219, 0.9);
    border-radius: 16px;
    margin-bottom: 20px;
}

.mobile-cart-enter {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #0a061e;
}

.mobile-cart-apply-btn {
    background: #3fda85;
    border-radius: 12px;
    border: none;
    padding: 0.6em;
    color: white;
}

.mobile-cart-tip {
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #000000;
}

.mobile-cart-tip-card {
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.11);
    border-radius: 22px;
}

.mobile-cart-tip-cmnt {
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #0a061e;
}

.mobile-cart-tip-btn {
    border: 1px solid #32ba7c;
    border-radius: 10px;
    color: #32ba7c;
    background-color: white;
}

.mobile-cart-tip-active {
    border: 1px solid #32ba7c;
    border-radius: 10px;
    color: #fff;
    background-color: #32ba7c;
}

.mobile-cart-yr-dtls {
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #000000;
}

.mobile-cart-chng {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ec2338;
    mix-blend-mode: normal;
}

.mobile-cart-ordr {
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #000000;
}

.mobile-cart-send {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #0a061e;
}

.mobile-cart-add {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ec2338;
    mix-blend-mode: normal;
}

.mobile-cart-cnltn-card {
    background: #ffffff;
    box-shadow: 0px 0px 20px 3px #ececec;
    /* border-radius: 1px; */
}

.mobile-cart-cn-hd {
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #000000;
}

.mobile-cart-pg {
    font-weight: 400;
    font-size: 13px;
    line-height: 2px;
    color: #0a061e;
}

.mobile-cart-bill-card {
    background: url(../images/cardbg.png),
        linear-gradient(98.81deg,
            rgba(254, 162, 75, 1) -0.82%,
            rgba(253, 136, 20, 1) 101.53%);
    background: url(../images/cardbg.png),
        linear-gradient(98.81deg,
            rgba(254, 162, 75, 1) -0.82%,
            rgba(253, 136, 20, 1) 101.53%);
}
.mobile-cart-bill-card_free_delivery_offer {
   background-color: #198754;
    border-radius: 22px 22px 0px 0px;
}
.mobile-cart-my-ordr-btn {
    background: #fefeff;
    border-radius: 15px;
    border: none;
    color: #ff9012;
    font-weight: 700;
    width: 100%;
    box-shadow: 0px 0px 20px 4px rgb(92 107 211 / 10%);
    border: 1px solid #fea24b;
}

.mobile-cart-prf-chng-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 9px;
    border: none;
}

/* cart end */

/* store */

.store-bg-img {
    background: url("../images/sidebg.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.store-search-button {
    box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
    color: #da6317;
    background: #fafdff;
    /* padding: 2vh; */
    /* margin-left: 5em; */
    font-size: 12px;
}

.mobile-ntfcn-heads {
    font-weight: 700;
    font-size: 25px;
    color: #09051c;
    background: url(../images/sidebg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.store-sub {
    color: #9c9595;
    font-weight: 600;
    margin-top: -1em;
    font-size: 13px;
}

.frame-img {
    /* background: linear-gradient(98.81deg, #f3c2c4 -0.82%, #ebb0b6 101.53%); */
    object-fit: cover;
    background-repeat: no-repeat;
    /* height: '20%'; */
    /* margin-left: 2em;
    margin-right: 2em; */
    mix-blend-mode: normal;
    border-radius: 16px;
}

.store-east {
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    margin-top: -1em;
    /* padding-left: 25px; */
    /* margin-left: -10.3em; */
    /* padding-bottom: 1em; */
}

.store-coupon-image {
    /* margin-left: 8em;
    margin-bottom: 2em; */
    object-fit: cover;
}

.store-cpn-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    border: none !important;
    outline: none !important;
}

.store-cpn-btn :focus {
    border: none !important;
    outline: none !important;
}

.store-time {
    background: rgba(255, 255, 255, 0.92);
    border-radius: 10px;
    color: #ff9012;
    margin-left: 1em;
    margin-top: 3em;
    padding-left: 0.5vh;
    padding-top: 0.1em;
}

.store-off {
    margin-top: -0.6em;
}

.store-delivery {
    background: rgba(255, 255, 255, 0.92);
    border-radius: 10px;
    color: #ff9012;
    margin-left: 1em;
    /* height: fit-content; */
    margin-top: 3em;
    width: 28vh;
    padding-left: 1vh;
}

.store-review {
    background: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    color: #a7a2a2;
    height: 20px;
    margin-top: -1em;
}

.coupon-store {
    font-size: 0.8em;
    background: #ffffff;
    box-shadow: 12px 26px 50px #5a6cea12;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #fea24b;
    white-space: nowrap;
    /* margin-top: 1em;
    margin-left: 1em; */
}

.store-menu {
    background: #ffffff;
    /* box-shadow: 0px 4px 14px #5768ec1f; */
    box-shadow: 0px 0px 2px #5768ec1f;
    border-radius: 19px;
}

.store-bottom-menu {
    border: 1px solid #ff9100;
    border-radius: 13px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ff9100;
    padding: 1em;
    margin-top: 1.2em;
}

.filter-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    width: 91%;
    padding: 12px 16px;
    height: 4em;
    /* margin-bottom: 1em; */
    outline: none !important;
    border: none !important;
}

.filter-button:focus {
    outline: none !important;
    border: none !important;
}

.filter-button:active {
    outline: none !important;
    border: none !important;
}

.store-filter-section {
    margin-bottom: 30%;
    margin-top: 2em;
}

/* food  start*/

.top-food-item-card {
    background: #fdfdfd;
    box-shadow: 0px 0px 9px 2px #bfbfbf5e;
    border-radius: 25px;
}

.top-food-color {
    color: #ff9a62;
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;
}

/* .top-food-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    height: 2.2rem;
    width: 4.5rem;
    border-radius: 8px;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
} */
.top-food-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    height: 2.2rem;
    width: 4.5rem;
    border-radius: 8px;
    background: white;
    border: 1px solid green;
}

.deal-top-food-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    height: 2.2rem;
    width: 2.1rem;
    border-radius: 8px;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
}

/* .top-food-add-min-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 10px;
    width: 1.8rem;
    border-radius: 8px;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
} */

.top-food-add-min-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 10px;
    width: 1.8rem;
    border-radius: 8px;
    border: 1px solid green;
}

.top-food-count {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
}

/* ribbon for top food */

.ribbon {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#fead1d 0%, #fead1d 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #fead1d;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #fead1d;
}

.ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #fead1d;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #fead1d;
}

/* ribbon for top food */

/* ribbon for stores */

.ribbon-buy {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon-buy span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#ffa136 0%, #fd8814 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbon-buy span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #fead1d;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #fead1d;
}

.ribbon-buy span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #fead1d;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #fead1d;
}


/* ribbon for stores small */

.ribbon-buy-small {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon-buy-small span {
    font-size: 9px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#ffa136 0%, #fd8814 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 15px;
    left: -26px;
}

.ribbon-buy-small span::before {
    content: "";
    position: absolute;
    left: 6px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #fead1d;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #fead1d;
}

.ribbon-buy-small span::after {
    content: "";
    position: absolute;
    right: 6px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #fead1d;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #fead1d;
}

.ribbon-buy-round {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon-buy-round span {
    font-size: 9px;
    font-weight: bold;
    color: #fff;
    padding: 5px;
    text-transform: uppercase;
    text-align: center;
    line-height: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 100%;
    background: linear-gradient(#ffa136 0%, #fd8814 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 0px;
    left: 0px;

}

.clipped {
    clip-path: polygon(50% 0%, 46.93% 3.1%, 43.47% 0.43%, 40.83% 3.9%, 37.06% 1.7%, 34.89% 5.49%, 30.87% 3.81%, 29.21% 7.85%, 25% 6.7%, 23.89% 10.92%, 19.56% 10.33%, 19.01% 14.66%, 14.64% 14.64%, 14.66% 19.01%, 10.33% 19.56%, 10.92% 23.89%, 6.7% 25%, 7.85% 29.21%, 3.81% 30.87%, 5.49% 34.89%, 1.7% 37.06%, 3.9% 40.83%, 0.43% 43.47%, 3.1% 46.93%, 0% 50%, 3.1% 53.07%, 0.43% 56.53%, 3.9% 59.17%, 1.7% 62.94%, 5.49% 65.11%, 3.81% 69.13%, 7.85% 70.79%, 6.7% 75%, 10.92% 76.11%, 10.33% 80.44%, 14.66% 80.99%, 14.64% 85.36%, 19.01% 85.34%, 19.56% 89.67%, 23.89% 89.08%, 25% 93.3%, 29.21% 92.15%, 30.87% 96.19%, 34.89% 94.51%, 37.06% 98.3%, 40.83% 96.1%, 43.47% 99.57%, 46.93% 96.9%, 50% 100%, 53.07% 96.9%, 56.53% 99.57%, 59.17% 96.1%, 62.94% 98.3%, 65.11% 94.51%, 69.13% 96.19%, 70.79% 92.15%, 75% 93.3%, 76.11% 89.08%, 80.44% 89.67%, 80.99% 85.34%, 85.36% 85.36%, 85.34% 80.99%, 89.67% 80.44%, 89.08% 76.11%, 93.3% 75%, 92.15% 70.79%, 96.19% 69.13%, 94.51% 65.11%, 98.3% 62.94%, 96.1% 59.17%, 99.57% 56.53%, 96.9% 53.07%, 100% 50%, 96.9% 46.93%, 99.57% 43.47%, 96.1% 40.83%, 98.3% 37.06%, 94.51% 34.89%, 96.19% 30.87%, 92.15% 29.21%, 93.3% 25%, 89.08% 23.89%, 89.67% 19.56%, 85.34% 19.01%, 85.36% 14.64%, 80.99% 14.66%, 80.44% 10.33%, 76.11% 10.92%, 75% 6.7%, 70.79% 7.85%, 69.13% 3.81%, 65.11% 5.49%, 62.94% 1.7%, 59.17% 3.9%, 56.53% 0.43%, 53.07% 3.1%);
}

.all-resturant-rating {
    color: #ff9a62;
    font-size: 15px;
    font-weight: 700;
}

.resturant-description {
    /* border-bottom: 1px dashed #d9d9d9; */
    font-size: 12px;

}

.resturant-description-top {
    border-top: 1px dashed #d9d9d9;
}

.address-delete {
    color: #ff3a3a;
    /* border: 1px solid #f30f0f36;
    box-shadow: 0px 0px 20px 0px rgba(90, 107, 234, 0.1);
    border-radius: 10px; */
}

.selected-address {
    font-size: 0.9em;
    color: #ee1d1d;
    border: 1px solid #ee1d1d7a;
    border-radius: 5px;
}

.all-resturant-delivery {
    color: #ff9a62;
    font-size: 16px;
    font-weight: 700;
}
@media (max-width: 400px) {
    .all-resturant-delivery {
        color: #ff9a62;
        font-size: 12px;
        font-weight: 700;
    }
    .mobile-cart-price {
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: #3b3b3b;
        mix-blend-mode: normal;
    }

}

@media (max-width: 400px) {
    .home-heading {
        font-weight: 700;
        font-size: 13px;
        color: #09051c;
    }
    .top-food-color {
        color: #ff9a62;
        font-size: 15px;
        font-weight: 700;
        white-space: nowrap;
    }
}
/* food filter */

#dropdown-basic-button {
    background: transparent;
    color: #5a595a;
    border: 1px solid #5a595a;
    border-radius: 20px;
    padding: 3px 4px;
    margin-right: 10px;
}

.filter-menu {
    display: grid;
    overflow-x: scroll;
    background: none !important;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

#dropdown-basic-button:focus {
    border: 1px solid #5a595a;
    outline: none;
}

/* food filter */

/* food  end*/

/* refer and earn */

.wid-wallet-button {
    background-color: #38d79f;
    border-radius: 10px;
    border: none;
}

.progress-bar {
    background-color: #fea24b !important;
}

.invite-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    border: none;
}

.border-bottom {
    border-bottom: 1px solid;
}

/* refer and earn */

/* explore menu starts */

.notification-explore-menu {
    margin-right: 1em;
    background: #fff;
    /* box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2); */
    border-radius: 15px;
    padding: 1%;
}

.explore-menu-searchBar {
    height: 50px;
    background: #fff9f2;
    border-radius: 15px;
    width: 100%;
}
.explore-menu-searchBar2 {
    height: 50px;
    background: #fff9f2;
    border-radius: 15px;
    width: 100%;
}

.filter-image-exploremenu {
    background: #fff9f2;
    border-radius: 15px;
    padding: 1em;
}

.explore-menu-image {
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
    padding-bottom: 1.2em;
    padding-top: 1.2em;
    /* margin: 1.4em; */
}

.explore-menu-store {
    background: #ffffff;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
    width: 145px;
    height: 184px;
}

.explore-menu-filter-item {
    border: 1px solid #d9d9d9;
    border-radius: 13px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #b0afb5;
    padding: 1em;
    margin-top: 1.2em;
}

.explore-menu-filter-tag {
    background: rgba(254, 173, 29, 0.1);
    mix-blend-mode: normal;
    border-radius: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.428571px;
    color: #da6317;
    width: 100%;
    /* height: 10%; */
}

/* explore menu ends */

/* your coupon */

.mobile-your-coupon-card {
    border-radius: 24px;
    border-color: orange;
    border-style: solid;
}

.mobile-yr-cpn-pr {
    font-weight: 700;
    font-size: 18px;
    color: #050505;
}

.mobile-yr-cpn-str {
    margin-right: 10px;
}

.mobile-yr--cpn-sbcr-btn {
    width: 100%;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    border: none;
    color: white;
}

/* your coupon */

/* top offers start */

.mobile-top-offer-bg {
    background: url("../images/sidebg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    overflow-x: hidden;
}

.mobile-top-offer-search {
    box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
    color: #da6317;
    background: #fafdff;
    width: 45px;
    height: 45px;
}

.mobile-top-offer-img {
    width: 100%;
    height: 155px;
    border-radius: 16px;
}

.mobile-top-offer-item-div {
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(90, 108, 234, 0.1);
    border-radius: 19px;
}

.mobile-top-offer-item-img {
    width: 28vw;
    height: 28vw;
    border-radius: 10px;
}

.mobile-top-offer-item {
    font-weight: 600;
    color: #09051c;
}

.mobile-top-offer-item-desc {
    font-weight: 500;
    font-size: 12px;
    color: #b3b2b6;
}

.like {
    background: #fbecdb;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    /* color: #FF9012; */
}

/* top offers end */

/* mobile-detailmenu css starts here */

.mobile-detailmenu-starrating {
    padding: 0px 25px;
    margin-left: 109px;
}

.mobile-detailmnu-star-div {
    margin-left: 90px;
}

.mobile-detailmenu-deliversdbtn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 10px;
    border: 1px black;
    color: white;
}

/* .mobile-detailmnu-rate-star {
    color: #ff9012;
} */

/* mobile-detailmenu ends here  */

/* restaraunt filter starts */

.rest-filter {
    border-radius: 15px;
    background: #fff9f2;
    width: 27%;
    padding: 0.7em;
}

.restaraunt-image {
    background: #ffffff;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
}

.add-button {
    border: none;
    color: #fff;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 12px;
    font-weight: 700;
}

.add-button-meals {
    border: none;
    color: #fff;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 10px;
    font-weight: 700;
}

.cancel-button-meals {
    border: none;
    color: #fff;
    background: linear-gradient(98.81deg, #ff5749 -0.82%, #c54d3b 101.53%);
    border-radius: 10px;
    font-weight: 700;
}

.timeout-number {
    color: #ff7979;
    font-weight: 700;
    border-radius: 50%;
    width: 9vw;
    height: 9vw;
    border: 1px dashed #ff7979;
}

.delete-button-meals {
    border: 1px solid #fd8814;
    color: #fd8814;
    background: #fff;
    border-radius: 10px;
    font-weight: 700;
}

.item-count {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.571429px;
    color: #181818;
    mix-blend-mode: normal;
}

.apply-coupon-button {
    border-radius: 14px;
    background-color: #3fda85;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
}

.apply-coupon-button:hover {
    border-radius: 14px;
    background-color: #3fda85;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
}

.apply-coupon-button:focus {
    border-radius: 14px;
    background-color: #3fda85;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
}

/* restaraunt filter ends */

/* scratch card start */

.scratch-card {
    background: url("../images/scratch-card-bg.png"),
        linear-gradient(35.16deg, #ffe259 -18%, #ffa751 99.52%);
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 12px;
    color: #fff;
    font-weight: 800;
    font-size: 20px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    width: 43vw;
    border: none;
    height: 43vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* scratch card end */

/* single coupon start */

.mobile-single-coupon-img {
    width: 100%;
    height: 150px;
    border-radius: 16px;
}

.mobile-single-coupon-item {
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(90, 108, 234, 0.07);
    border-radius: 22px;
    /* height: 60px; */
}

/* single coupon end */

/* edit profile */

.edit-profile-button {
    background: #fea24b;
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    outline: none;
}

.card-profile {
    box-shadow: 0px 4px 14px rgba(90, 108, 234, 0.1);
    width: 33vw;
    height: 33vw;
    border-radius: 50%;
    border: 3px solid #fead1d;
    justify-content: center;
    align-items: center;
}

/* edit end */

/* liked items start */

.mobile-liked-heading {
    background: url("../images/sidebg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: 10vh;
}

.mobile-liked-item-div {
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(90, 108, 234, 0.1);
    border-radius: 19px;
}

.mobile-liked-item-img {
    width: 95px;
    height: 98px;
    border-radius: 10px;
}

.mobile-liked-item {
    font-weight: 600;
    line-height: 131.02%;
    color: #09051c;
}

.mobile-liked-item-desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 131.02%;
    color: #b3b2b6;
}

.filter-like-div {
    border-radius: 15px;
    width: 91%;
    padding: 12px 16px;
    height: 4em;
    margin-bottom: 1em;
}

.filter-like-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    width: 91%;
    padding: 12px 16px;
    height: 4em;
    margin-bottom: 1em;
    border: none !important;
    outline: none !important;
}

.filter-like-button:focus {
    outline: none !important;
    border: none !important;
}

.filter-like-button:active {
    outline: none !important;
    border: none !important;
}

/* liked items end */

/* top restuarnt  */

.top-res-header {
    font-weight: 700;
    font-size: 18px;
    color: #09051c;
}

.top-res-offer {
    color: #595959;
    font-weight: 800;
    font-size: 13px;
    background-color: #fff;
    border-radius: 5px;
}

.top-res-name {
    font-weight: 600;
    font-size: 15px;
    color: #09051c;
}

.top-res-deli-time {
    font-weight: 400;
    font-size: 11px;
}

/* top restuarnt  */

/* popular brands  */
.countdown-deal-today {
    font-size: 0.8em !important;
    color: #e30000 !important;
    font-weight: 700 !important;
}

.popular-brand-offer {
    background-color: #fead1d;
    font-weight: 800;
    font-size: 11px;
    color: #fff;
    bottom: 12px;
    left: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}

.popular-tag-content-top {
    position: absolute;
    top: 5%;
    width: 100%;
}

.popular-tag-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 0px 0px 15px 15px;
    text-align: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.568), transparent)
        /* background: #fead1d; */
        /* font-size: 0 !important; */
        /* background: linear-gradient(to bottom, #fead1d, #fead1d); */
        /* box-shadow: rgba(0, 0, 0, 0.4) 0 3px 3px; */
}

.popular-tag {
    border-radius: 8px;
    text-align: center;
    background: #fead1d;
    font-size: 0 !important;
    background: linear-gradient(to bottom, #fead1d, #fead1d);
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 1px;
}

.popular-tag p {
    font-size: 11px !important;
    color: #ffffff;
    text-shadow: #ffffff 0 1px 0;
    margin: 0px;
    padding: 2px 4px;
}

/* popular brands  */

/* restaurants near you  */

.restuarant-near-offer {
    font-weight: 800;
    font-size: 13px;
    color: #fd8814;
    background-color: #fff;
    border-radius: 5px;
}

.all-restuarant-for-you-offer {
    font-weight: 750;
    font-size: 19px;
    color: #f67b00;
    /* background-color: rgba(0,0,0, 0.4); */
    border-radius: 5px;
}

.all-restuarant-for-you-offer2 {
    /* font-weight: 600; */
    font-size: 14px;
    color: #fff;
    /* background-color: #fff; */
    border-radius: 5px;
}

.all-restuarant-for-you-coupon1 {

    font-weight: 900;
    font-size: 18px;
    color: #ffffff;
    /* background-color: rgba(0,0,0, 0.4); */
    border-radius: 5px;
}

.all-restuarant-for-you-coupon2 {

    font-weight: 600;
    font-size: 10px;
    color: #fff;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.image-transperant {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(1px);
}

.restuarant-near-ad {
    max-width: 80px;
}

/* restaurants near you  */

/* set location  */

.set-location-searchbar {
    background-color: #fff;
    border-radius: 15px;
}

.set-loaction-input {
    width: 100%;
}

.location-input {
    border: none;
}

.location-input:focus {
    outline: none;
}

.setlocation-address-type {
    background-color: #fff;
    border-radius: 15px;
}

.set-location-address-btn {
    background-color: #fff;
    border-radius: 11px;
    border: 1px solid #fe8b19;
    color: #fe8b19;
    width: 30%;
    height: 40px;
}

.set-location-address-btn-active {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 11px;
    border: none;
    color: #fff;
    width: 30%;
    height: 40px;
}

.set-location-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    box-shadow: 0px 0px 50px rgba(90, 108, 234, 0.07);
    border-radius: 15px;
    border: none;
    color: #fff;
    width: 100%;
}

/* set location  */

/* .google-places-autocomplete__suggestions {
    display: none;
}
.google-places-autocomplete__input {
    border: none;
    width: 100%;
    padding: 0.5rem;
}
.google-places-autocomplete__input:focus {
    outline: none;
    width: 100%;
    padding: 0.5rem;
} */

.set-current-location-btn {
    width: 100%;
    height: 60px;
    border-radius: 15px;
    background-color: #fff;
    color: #ff9012;
    font-weight: 700;
    font-size: 14px;
    border: 1px solid #ff9012;
}

.search-input-location {
    outline: none;
    width: 100%;
    padding: 0.5rem;
    border: none;
    height: 60px;
    display: flex;
    align-items: center;

}

.location-main-name {
    font-weight: 600;
    font-size: 17px;
}

.css-yk16xz-control {
    border: none;
    outline: none;
}

.css-1hwfws3 {
    border: none;
    outline: none;
}

/* my address */

.mobile-address-card {
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(90, 107, 234, 0.1);
    border-radius: 12px;
}

.mobile-address-card-selected {
    background: #ffedd6;
    box-shadow: 0px 0px 20px 0px rgba(90, 107, 234, 0.1);
    border-radius: 12px;
}

.address-page-icons {
    color: #f97b00;
}

.radio-btn-on {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #8399a9;
    border: 2px solid white;
    outline: 2px solid #8399a9;
}

.auth-error-cus {
    position: fixed;
    bottom: 0px;
    text-align: center;
    width: 100%;
    left: 0px;
    background-color: rgb(206, 0, 96);
    color: white;
    padding: 0.8rem;
    /* z-index: 2147483647; */
    z-index: 999999999;
}

.no-click {
    pointer-events: none;
}

.error-shake {
    -webkit-animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.cart-image-delete {
    border: none;
    color: #fff;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 0px 0px 10px 10px;
    font-weight: 700;
}

.float-cart {
    display: hidden;
    position: fixed;
    width: 94%;
    left: 3%;
    border-radius: 10px;
    bottom: 5%;
    height: 4rem;
    padding: 0.5rem;
    opacity: 0;
    bottom: 12vh;
    -webkit-transition: 0.2s all linear;
    -o-transition: 0.2s all linear;
    transition: 0.2s all linear;
    z-index: 9999;
}

.ReactModal__Overlay--after-open {
    z-index: 9999;
}

.float-cart--open {
    /* display: block; */
    opacity: 1;
    bottom: 14vh;
    -webkit-transition: 0.2s all linear;
    -o-transition: 0.2s all linear;
    transition: 0.2s all linear;
    box-shadow: 0px 2px 25px 12px rgb(0 0 0 / 10%);
    background-color: #ffffff;
}

.float-cart-no-footer {
    /* display: block; */
    opacity: 1;
    bottom: 6vw;
    -webkit-transition: 0.2s all linear;
    -o-transition: 0.2s all linear;
    transition: 0.2s all linear;
    box-shadow: 0px 2px 25px 12px rgb(0 0 0 / 10%);
    background-color: #ffffff;
}

.float-cart-browse {
    /* display: block; */
    opacity: 1;
    bottom: 20vw;
    -webkit-transition: 0.2s all linear;
    -o-transition: 0.2s all linear;
    transition: 0.2s all linear;
    box-shadow: 0px 2px 25px 12px rgb(0 0 0 / 10%);
    background-color: #ffffff;
}

.float-cart-product {
    /* display: block; */
    opacity: 1;
    bottom: 24vw;
    -webkit-transition: 0.2s all linear;
    -o-transition: 0.2s all linear;
    transition: 0.2s all linear;
    box-shadow: 0px 2px 25px 12px rgb(0 0 0 / 10%);
    background-color: #ffffff;
}

.cour-btn {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
}

/* my address */

/* customization */

.addon-category-add {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 10px;
    font-weight: 700;
    font-size: 16px;
    color: #fefeff;
    border: none !important;
    width: 45%;
}

.addon-category-skip {
    border: 1px solid #fd8814;
    color: #fd8814;
    background: #fff;
    border-radius: 10px;
    font-weight: 700;
    font-size: 16px;
    width: 45%;
}

.addon-list {
    font-weight: 600;
    font-size: 16px;
    color: #38383a;
}

.addon-price {
    font-weight: 700;
    font-size: 16px;
    color: #e77a0d;
}

.try-btn {
    color: #fff;
    background: #fd8814;
    border-radius: 10px;
    font-weight: 700;
    font-size: 16px;
    width: 45%;
}

.input-group .btn {
    position: relative;
    z-index: 0;
}

.form-control {
    padding: 0.375rem 0.5rem !important;
}

.form-control:focus {
    padding: 0.375rem 0.5rem !important;
    border-color: none;
    box-shadow: none;
}

.no-click {
    pointer-events: none;
}

.auth-error-cus {
    position: fixed;
    bottom: 6.5rem;
    text-align: center;
    width: 100%;
    left: 0px;
    background-color: #e98007;
    color: white;
    padding: 0.8rem;
    /* z-index: 2147483647; */
    z-index: 999999999;
}

.error-shake {
    -webkit-animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

/* customization */

.redeem-btn {
    font-size: 14px;
    color: white;
    background-color: #fea24b;
    border-radius: 5px;
}

.grab-back {
    background: rgba(206, 206, 206, 0.25);
    box-shadow: 0px 0px 20px 5px #5155936e;
    backdrop-filter: blur(17.5px);
    -webkit-backdrop-filter: blur(17.5px);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    text-shadow: 0 0 20px #0020ff;
}

.nav-tabs {
    border-bottom: none !important;
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: 600;
    color: #fea24b;
    width: 100%;
    background-color: #fff;
    text-decoration: none;
    border-radius: 12px !important;
    border: 1px solid #fea24b !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
}

.nav-link:active {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: 600;
    color: #000;
    width: 100%;
    text-decoration: none;
    border-radius: 12px !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
}

.nav-tabs .nav-link.active {
    color: #fff;
    font-weight: 700;
    width: 100%;
    border-radius: 12px !important;
    background-color: #fea24b;
    border-color: #fea24b;
}

.nav-fill .nav-item {
    flex: 0 !important;
}

.filter-btn {
    border: 1px solid #000;
    border-radius: 50px;
    white-space: nowrap;
    color: #000;
    padding-left: 35px;
    padding-right: 35px;
    background-color: #fff;
}

.filter-btn-active {
    border: 1px solid #000;
    border-radius: 50px;
    white-space: nowrap;
    padding-left: 35px;
    padding-right: 35px;
    color: #fff;
    background-color: #000;
}

.filter-select-btn {
    border: 1px solid #000;
    border-radius: 50px;
    white-space: nowrap;
    color: #000;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #fff;
}

.filter-select-btn-active {
    border: 1px solid #000;
    border-radius: 50px;
    white-space: nowrap;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    background-color: #000;
}

.input-deals-today {
    background-color: #fff;
    padding: 0.85rem 1.5rem;
    border-radius: 10px;
    border: 0.1px solid #f4f4f4;
    outline: none !important;
}

.form-check-input {
    width: 2em !important;
    height: 1.3em !important;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.form-check-input:focus {
    background-color: #fff;
    border-color: #fff;
    border: 1px solid #bfbfbf;
    outline: 0;
    box-shadow: none;
}

.form-check-input:hover {
    background-color: #fff;
    border-color: #fff;
    border: 1px solid #bfbfbf;
    outline: 0;
    box-shadow: none;
}

.form-check-input:checked {
    background-color: #fea24b;
    border-color: #fea24b;
}

/* map */

.center-marker {
    position: absolute;
    top: 34%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
}

.center-marker-full {
    position: absolute;
    top: 48%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
}

.center-marker-pulse::after {
    position: absolute;
    content: "";
    top: 29.3%;
    left: 50.7%;
    height: 140px;
    width: 140px;
    /* -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: marker-pulse 1.2s ease-out infinite;
    animation: marker-pulse 1.2s ease-out infinite;
    background: rgba(255, 152, 0, 0.2);
    border: 2px solid #ff9800;
    border-radius: 100%;
    pointer-events: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    will-change: transform; */
}

/* cart sticky */
.section-body {
    background: lightblue;
    padding: 2em 1em;
}

.header-body {
    position: fixed;
    transition: 0.1s;
}

.cart-no-address {
    background: #ff9012;
    border-radius: 15px;
    border: none;
    color: #fefeff;
    font-weight: 700;
    width: 100%;
    box-shadow: 0px 0px 20px 4px rgb(92 107 211 / 10%);
}

.home-location-image {
    background-image: url(../images/2980960.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: auto;
    height: auto;
}

.cancel-card {
    background-color: #fff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.11);
    border-radius: 22px;
    border: 1px solid #f3870d;
}

.store-box {
    border-left: 2px solid #c2c2c2;
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 7px;
}

.prescription-icon {
    font-size: 0.9em;
    font-weight: 650;
    color: #c40000;
}

.with-prescription {
    background: linear-gradient(98.81deg, #ff9e40 -0.82%, #fff1e2 101.53%);
}

.mobile-place-review-btn {
    border-radius: 14px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.05em;
    background: linear-gradient(91.88deg, #fead1d 28.33%, #ffa137 93.06%);
    color: #fff;
    border: none;
}

.mobile-place-review-btn-skip {
    border-radius: 15px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.05em;
    background: #fff;
    color: #fead1d;
    box-shadow: 0px 0px 20px 5px #eaeaea;
    border: none;
}

.form-group .addon-lists {
    margin-bottom: 10px;
}

.addon-label {
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
}

.addon-label-price {
    color: #93959f;
}

/*
input {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
} */

.delivery-type {
    border: 1px solid #fea24b;
    padding: 0.6rem 0.8rem;
    border-radius: 0.6rem;
    font-weight: 600;
    width: 47%;
    color: #fea24b;
    text-align: center;
}

.active-type {
    padding: 0.6rem 0.8rem;
    background-color: #fea24b;
    border-radius: 0.6rem;
    font-weight: 600;
    color: #fff;
    width: 47%;
    text-align: center;
}

@-moz-document url-prefix() {
    select {
        direction: rtl;
    }

    option {
        text-align: left;
    }
}

.form-select:focus,
.form-select:hover {
    box-shadow: none;
    float: left;
    menu-placement: 'left'
}

.example-enter {
    /* animation: flasher 0.5s;  */
    transition-property: height;
    transition-duration: 0.3s;
    transition-timing-function: linear;
}

@keyframes flasher {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

